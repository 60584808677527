import types from './types';
import get from "lodash/get";
import {apiConfig} from "../../config";
import Axios from "../../utils/Axios";

export const toggleJoinUs = () => ({
    type: types.APP_STATIC__TOGGLE_JOIN_US,
});

export const toggleGetOff = () => ({
    type: types.APP_STATIC__TOGGLE_GET_OFF,
});

export const setUser = user => ({
    type: types.APP_STATIC__SET_USER,
    payload: {user},
});

export const setOauth = oauth => ({
    type: types.APP_STATIC__SET_OAUTH,
    payload: {oauth},
});

export const logOut = () => ({
    type: types.APP_STATIC__LOG_OUT,
});

export const generateSessionId = () => ({
    type: types.APP_STATIC__GENERATE_SESSION_ID,
});

export const logOutSettings = () => ({
    type: types.SETTINGS__ON_LOG_OUT,
});

export const logInSettings = () => ({
    type: types.SETTINGS__LOG_IN,
});
export const setManagingSubscription = () => ({
    type: types.APP_STATIC__SET_MANAGING_SUBSCRIPTION,
});
export const clearManagingSubscription = () => ({
    type: types.APP_STATIC__CLEAR_MANAGING_SUBSCRIPTION,
});
export const setSessionId = (sessionId, isManagingSubscription) => ({
    type: types.APP_STATIC__SET_SESSION_ID,
    payload: {sessionId, isManagingSubscription},
});

export function toggleOffCanvasMobileNav(isOffCanvasMobileNav) {
    return {type: types.TOGGLE_OFFCANVAS_MOBILE_NAV, isOffCanvasMobileNav: isOffCanvasMobileNav}
}

export const logOutAppStatic = () => ({
    type: types.APP_STATIC__LOG_OUT,
});

export const onLogIn = (login, password) => dispatch => {
    Axios.post(`${apiConfig.host}/oauth/token`, {
        grant_type: 'password',
        client_id: apiConfig.client_id,
        client_secret: apiConfig.client_secret,
        username: login,
        password: password,
        scope: '',
    }, {
        headers: {
            Accept: "application/vnd.api+json",
        }
    })
        .then(oauthResponse => {
            const oauth = get(oauthResponse, 'data', {});
            Axios.get(`${apiConfig.host}/api/v1/user?include=addresses`, {
                headers: {
                    Accept: "application/vnd.api+json",
                    "Account-Token": apiConfig.aToken,
                    "Authorization": `${oauth.token_type} ${oauth.access_token}`,
                },
            })
                .then(userResponse => {
                    const user = get(userResponse, 'data.data', {});
                    dispatch({
                        type: types.APP_STATIC__ON_LOG_IN,
                        payload: {
                            oauth,
                            user,
                        },
                    });
                    dispatch(logInSettings());
                });
        });
};


// old
export const patchProfile = data => dispatch => Axios.patch('/users/profile', data)
    .then(res => {
        const user = get(res, 'data.data', {});
        dispatch({
            type: types.APP_STATIC__UPDATE_PROFILE,
            payload: {
                user,
            },
        })
    });

export const patchPassword = data => () => Axios.patch('/users/password', data);

export const getUserAddress = () => dispatch => Axios.get('/users/addresses')
    .then(res => {
        const address = get(res, 'data.data', {});
        dispatch({
            type: types.APP_STATIC__GET_ADDRESS,
            payload: {
                address,
            },
        })
    });

export const updateAddress = data => (dispatch, getState) => {
    const addressId = get(getState(), 'appStatic.address[0].id', null);
    if (addressId) {
        return Axios.patch(`/users/addresses/${addressId}`, {
            address1: data.address1,
            address2: data.address2,
            country: data.country,
            city: data.city,
            state: data.state,
            zip: data.zip,
            is_default: true,
        })
            .then(res => {
                const address = get(res, 'data.data', {});
                dispatch({
                    type: types.APP_STATIC__GET_ADDRESS,
                    payload: {
                        address: [address],
                    },
                })
            });
    }
    return Axios.post('/users/addresses', {
        address1: data.address1,
        address2: data.address2,
        country: data.country,
        city: data.city,
        state: data.state,
        zip: data.zip,
        is_default: true,
    })
        .then(res => {
            const address = get(res, 'data.data', {});
            dispatch({
                type: types.APP_STATIC__GET_ADDRESS,
                payload: {
                    address: [address],
                },
            })
        });
};

