import React from 'react';
import logo from 'assets/img/logoAsset.png';
import cn from 'classnames';
import { history } from '../../history';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

class Navigation extends React.Component {
  goTo = (e, path) => {
    e.preventDefault();
    if (history.location.pathname === path && path === '/order-plan') {
      window.__reset_order_plan__();
    }
    if (history.location.pathname === path && path === '/order-meal') {
      window.__reset_meal__();
    }
    history.push(path);
  }

  render() {
    const navCN = cn({
      "navbar navbar-expand-lg navbar-light fixed-top py-3": true,
      "mo": this.props.showJoinUs,
    })
    return (
      <nav className={navCN} id="mainNav">
        <div className="container">
          <a className="navbar-brand js-scroll-trigger" href="/" onClick={e => this.goTo(e, '/')}>
            <img src={logo} height="50" alt={'logo'} />
          </a>
          <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto my-2 my-lg-0 d-md-flex align-items-center">
              <li className="nav-item">
                <a className="nav-link" href="meal-plans" onClick={e => this.goTo(e, '/meal-plans')}>
                  MEAL PLANS
                </a>
              </li>
              {/*<li className="nav-item">*/}
              {/*  <a className="nav-link" href="weekly-meals" onClick={e => this.goTo(e, '/weekly-meals')}>*/}
              {/*    WEEKLY MENUS*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li className="nav-item">
                <a className="nav-link" href="order-meal" onClick={e => this.goTo(e, '/order-meal')}>
                  À LA CARTE
                </a>
              </li>
              {
                isEmpty(this.props.user) ?
                  <li className="nav-item">
                    <a className="nav-link" href="login" onClick={e => this.goTo(e, '/login')}>
                      LOGIN
                    </a>
                  </li> :
                  <li className="nav-item">
                    <a className="nav-link" href="/profile/">
                      MY ACCOUNT
                    </a>
                  </li>
              }
              <li className="nav-item pl-md-3">
                <a className="nav-link btn-secondary" href="/order-plan" onClick={e => this.goTo(e, '/order-plan')}>
                  GET STARTED
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => ({
  user: state.appStatic.user,
});

export default connect(mapStateToProps)(Navigation);

