import types from './types';

const initialState = {
  plans: [],
  menus: [],
  mealModal: {},
};

const meal = (state = initialState, action) => {
  switch (action.type) {
    case types.MEAL__GET_HOME_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case types.MEAL__GET_PLANS:
      return {
        ...state,
        ...action.payload,
      };
    case types.MEAL__GET_MENUS:
      return {
        ...state,
        ...action.payload,
      };
    case types.MEAL__SET_MENUS_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    default: {
      return state;
    }
  }
};

export default meal;
