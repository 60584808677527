export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const TOGGLE_BOXED_LAYOUT = 'TOGGLE_BOXED_LAYOUT';
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const TOGGLE_OFFCANVAS_NAV = 'TOGGLE_OFFCANVAS_NAV';
export const TOGGLE_FIXED_SIDENAV = 'TOGGLE_FIXED_SIDENAV';
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER';
export const CHANGE_SIDENAV_WIDTH = 'CHANGE_SIDENAV_WIDTH';
export const TOGGLE_OFFCANVAS_MOBILE_NAV = 'TOGGLE_OFFCANVAS_MOBILE_NAV';
export const CHANGE_COLOR_OPTION = 'CHANGE_COLOR_OPTION';
export const CHANGE_THEME = 'CHANGE_THEME';
export const TOGGLE_HEADER_SHADOW = 'TOGGLE_HEADER_SHADOW';
export const TOGGLE_SIDENAV_SHADOW = 'TOGGLE_SIDENAV_SHADOW';
export const SETTINGS__ON_LOG_OUT = 'SETTINGS__ON_LOG_OUT';
export const SETTINGS__LOG_IN = 'SETTINGS__LOG_IN';
