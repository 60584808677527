import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import redux from 'reduxStore';
import AlertTemplate from 'react-alert-template-basic';
import Layout from 'containers/Layout'
import * as serviceWorker from 'serviceWorker';
import './styles.css';
import './styles_override.css';
import Router from 'Router';


const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  transition: transitions.SCALE
}

ReactDOM.render(
  <Provider store={redux.store}>
    <AlertProvider template={AlertTemplate} {...options}>
      <PersistGate loading={null} persistor={redux.persistor}>
        <Layout>
          <Router />
        </Layout>
      </PersistGate>
    </AlertProvider>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
