import React from 'react';
import close from 'assets/img/close.png';
import cn from "classnames";

class JoinUs extends React.Component {
    toggleModal = e => {
        e.preventDefault();
        this.props.onHide();
    }

    render() {
        const navCN = cn({
            "preview-bar hidden fixed-top py-2": true,
            "open": this.props.showJoinUs,
        })
        return (
            <div className={navCN} id="top-bar">
                <div className="container-fluid h-100 position-relative">
                    <div className="row align-items-center justify-content-center text-center h-100">
                        <div className="d-flex align-items-center justify-content-around">
                            <p className="mb-0">
                                Join Us Today For The Chance To Receive 20% Off Your First Order
                            </p>
                            <a href="/" className="btn-white btn-small ml-3" onClick={e => {
                                e.preventDefault();
                                window.$('#newsletter').modal('show');
                            }}>
                                Join
                            </a>
                        </div>
                    </div>
                    <a href="/" id="close" onClick={this.toggleModal}>
                        <img src={close} width="15" alt={'close'}/>
                    </a>
                </div>
            </div>
        )
    }
}

export default JoinUs;
