import APPCONFIG from 'constants/appConfig';
import * as types from 'constants/actionTypes';

const initialSettings = APPCONFIG.settings;

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case types.CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.layoutOption
      };
    case types.TOGGLE_BOXED_LAYOUT:
      return {
        ...state,
        boxedLayout: action.isBoxedLayout
      };
    case types.TOGGLE_FIXED_SIDENAV:
      return {
        ...state,
        fixedSidenav: action.isFixedSidenav
      };
    case types.TOGGLE_FIXED_HEADER:
      return {
        ...state,
        fixedHeader: action.isFixedHeader
      };
    case types.TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        collapsedNav: action.isCollapsedNav
      };
    case types.TOGGLE_OFFCANVAS_NAV:
      return {
        ...state,
        offCanvasNav: action.isOffCanvasNav
      };
    case types.CHANGE_SIDENAV_WIDTH:
      return {
        ...state,
        sidenavWidth: action.sidenavWidth
      };
    case types.TOGGLE_OFFCANVAS_MOBILE_NAV:
      return {
        ...state,
        offCanvasMobileNav: action.isOffCanvasMobileNav
      };
    case types.CHANGE_COLOR_OPTION:
      return {
        ...state,
        colorOption: action.colorOption
      };
    case types.CHANGE_THEME:
      return {
        ...state,
        theme: action.themeOption
      };
    case types.TOGGLE_HEADER_SHADOW:
      return {
        ...state,
        headerShadow: action.hasHeaderShadow
      };
    case types.TOGGLE_SIDENAV_SHADOW:
      return {
        ...state,
        sidenavShadow: action.hasSidenavShadow
      };

    // new
    case types.SETTINGS__ON_LOG_OUT:
      return {
        ...state,
        layout: '4',
        showCustomizer: false,
        customizer: false,
      };
    case types.SETTINGS__LOG_IN:
      return {
        ...state,
        layout: '1',
        showCustomizer: true,
        customizer: true,
      };
    default:
      return state;


  }
}

export default settings;
