import React from 'react';
import { Layout } from 'antd';
const { Footer } = Layout;

const AppFooter = () => (
  <Footer className="app-footer app-footer-custom bg-light py-3">
    <div className="">
      <p className="medium text-center m-0">
        © {new Date().getFullYear()} Nationwide Fit Meals. All Rights Reserved.
      </p>
    </div>
  </Footer>
)

export default AppFooter;
