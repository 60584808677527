import types from './types';

const initialState = {
    list: [],
};

const transactions = (state = initialState, action) => {
    switch (action.type) {
        case types.TRANSACTIONS__GET_TRANSACTIONS:
            return {
                ...state,
                ...action.payload
            };
        case types.TRANSACTIONS__MANAGE_TRANSACTIONS:
            return {
                ...state,
                ...action.payload
            };
        default: {
            return state;
        }
    }
};

export default transactions;
