import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import JoinUs from 'components/Layout/JoinUs';
import Navigation from 'components/Layout/Navigation';
import Footer from 'components/Profile/Footer';
import BottomInfoBlock from 'components/Layout/BottomInfoBlock';
import { toggleGetOff, toggleJoinUs } from 'reduxStore/appStatic/actions';

class Layout extends React.Component {
  navbarCollapse = () => {};

  componentDidMount() {
    const { showGetOff } = this.props.appStatic;
    const $ = window.$;
    $('#close').click(function (e) {
      e.preventDefault();
      $(".hidden").toggleClass('open');
      $("#mainNav").toggleClass('mo');
    });
    if (showGetOff) {
      $('#newsletter').modal('show');
    }

    $('body').scrollspy({
      target: '#mainNav',
      offset: 75
    });
    // Collapse Navbar
    this.navbarCollapse = function() {
      if ($("#mainNav").offset().top > 5) {
        $("#mainNav").addClass("navbar-scrolled");
      } else {
        $("#mainNav").removeClass("navbar-scrolled");
      }
    };
    // Collapse now if page is not at top
    this.navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(this.navbarCollapse);
  }

  render() {
    return (
      <Fragment>
        {/*<GetOff onHide={this.props.toggleGetOff} />*/}
        <JoinUs
          onHide={() => {
            this.props.toggleJoinUs();
            setTimeout(() => {
              this.navbarCollapse();
            });
          }}
          showJoinUs={false}
        />
        <Navigation showJoinUs={false} />
        <div className="site-content">
          {this.props.children}
        </div>
        <BottomInfoBlock />
        <Footer />
      </Fragment>
    )
  }
}


const mapStateToProps = state => ({
  appStatic: state.appStatic,
});

export default connect(
  mapStateToProps,
  {
    toggleGetOff,
    toggleJoinUs,
  }
)(Layout);
