import types from './types';

const initialState = {
  list: [],
};

const appStatic = (state = initialState, action) => {
  switch (action.type) {
    case types.CARDS__GET_CARD_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case types.CARDS__ADD_CARD:
      return {
        ...state,
        list: [
          ...state.list,
          action.payload.card,
        ],
      };
    case types.CARDS__DELETE_CARD:
      return {
        ...state,
        list: state.list.filter(card => card.id !== action.payload.id),
      };
    default: {
      return state;
    }
  }
};

export default appStatic;
