import types from './types';
import cloneDeep from 'lodash/cloneDeep';

export const initialState = {
    paymentMethods: [],
    loading: false,
    planWithSnacks: false,
    zipResponse: {},
    zipCode: '',
    availableDays: [],
    selectedDay: '',
    plans: [],
    activePlan: {},
    allergies: [],
    selectedAllergies: [],
    daily: [],
    weekDays: '',

    subscriptions: {},
    menus: {},
    meals: [],
    selectedMeals: {},
    selectedDailyMeals: {},

    snacks: [],
    selectedSnacks: {},
    selectedAdditional: {},
    selectedAdditionalSnacks: {},

    subscriptionsResult: {},

    price: 0,
    currentPage: 'ZipCode',
    deliveryLimit: 0,
    giftCards: [],
};

const meal = (state = cloneDeep(initialState), action) => {
    switch (action.type) {
        case types.ORDER_MEAL__SET_FIELD:
            return {
                ...state,
                ...action.payload,
            };
        case types.ORDER_MEAL__RESET_STATE:
            return cloneDeep(initialState);
        default: {
            return state;
        }
    }
};

export default meal;
