import types from './types';

const initialState = {
    list: [],
    settings: null,
    isManaging: false
};

const subscriptions = (state = initialState, action) => {
    switch (action.type) {
        case types.SUBSCRIPTIONS__GET_SUBSCRIPTIONS:
            return {
                ...state,
                ...action.payload
            };
        case types.SUBSCRIPTIONS__MANAGE_SUBSCRIPTIONS:
            return {
                ...state,
                ...action.payload
            };
        default: {
            return state;
        }
    }
};

export default subscriptions;
