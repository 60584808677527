import types from './types';

const initialState = {
    sessionId: '',
    showJoinUs: false,
    showGetOff: true,
    oauth: {},
    user: {},
    isManagingSubscription: false
};


const appStatic = (state = initialState, action) => {
    switch (action.type) {
        case types.APP_STATIC__TOGGLE_GET_OFF:
            return {
                ...state,
                showGetOff: false,
            };
        case types.APP_STATIC__GENERATE_SESSION_ID:
            let a = Math.floor(1000 + Math.random() * 9000);
            a = a.toString();
            a = a.substring(-2);
            return {
                ...state,
                sessionId: `${new Date().getTime()}${a}`,
                isManagingSubscription: false,
            };
        case types.APP_STATIC__TOGGLE_JOIN_US:
            return {
                ...state,
                showJoinUs: false,
            };
        case types.APP_STATIC__SET_USER:
            return {
                ...state,
                ...action.payload,
            };
        case types.APP_STATIC__SET_OAUTH:
            return {
                ...state,
                ...action.payload,
            };
        case types.APP_STATIC__LOG_OUT:
            return {
                ...state,
                oauth: {},
                user: {},
            };
        case types.APP_STATIC__UPDATE_PROFILE:
            return {
                ...state,
                ...action.payload,
            }
        case types.APP_STATIC__GET_ADDRESS:
            return {
                ...state,
                ...action.payload,
            }
        case types.APP_STATIC__ON_LOG_IN:
            return {
                ...state,
                ...action.payload,
            }
        case types.APP_STATIC__SET_SESSION_ID:
            return {
                ...state,
                ...action.payload,
            }
        case types.APP_STATIC__SET_MANAGING_SUBSCRIPTION:
            return {
                ...state,
                isManagingSubscription: true,
            }
        case types.APP_STATIC__CLEAR_MANAGING_SUBSCRIPTION:
            return {
                ...state,
                isManagingSubscription: false,
            }
        default: {
            return state;
        }
    }
};

export default appStatic;
