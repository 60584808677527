import axios from 'axios';
import {hostUrl, apiConfig} from 'config';
import redux from 'reduxStore';

window.axios = axios;

class Axios {
    static get(url, data, options = {}) {
        const oauth = redux.store.getState().appStatic.oauth;
        const config = {
            ...options,
            headers: {
                ...options.headers,
                Accept: "application/vnd.api+json",
                "Account-Token": apiConfig.aToken,
                ...(oauth.access_token ? {Authorization: `${oauth.token_type} ${oauth.access_token}`} : {}),
            }
        };
        return axios.get(
            `${hostUrl}${url}`,
            config
        );
    }

    static post(url, data, options = {}) {
        const oauth = redux.store.getState().appStatic.oauth;
        const config = {
            ...options,
            headers: {
                ...options.headers,
                Accept: "application/vnd.api+json",
                "Account-Token": apiConfig.aToken,
                ...(oauth.access_token ? {Authorization: `${oauth.token_type} ${oauth.access_token}`} : {}),
            }
        };
        return axios.post(`${hostUrl}${url}`, data, config);
    }

    static put() {

    }

    static delete(url, data, options = {}) {
        const oauth = redux.store.getState().appStatic.oauth;
        const config = {
            ...options,
            headers: {
                ...options.headers,
                Accept: "application/vnd.api+json",
                "Account-Token": apiConfig.aToken,
                ...(oauth.access_token ? {Authorization: `${oauth.token_type} ${oauth.access_token}`} : {}),
            }
        };
        return axios.delete(`${hostUrl}${url}`, config);
    }

    static patch(url, data, options = {}) {
        const oauth = redux.store.getState().appStatic.oauth;
        const config = {
            ...options,
            headers: {
                ...options.headers,
                Accept: "application/vnd.api+json",
                "Account-Token": apiConfig.aToken,
                ...(oauth.access_token ? {Authorization: `${oauth.token_type} ${oauth.access_token}`} : {}),
            }
        };
        return axios.patch(`${hostUrl}${url}`, data, config);
    }

}

export default Axios;
